import 'core-js/stable';
import 'regenerator-runtime/runtime';

import 'stylesheets/x508';
import 'stylesheets/magic/amalgamation';
import 'stylesheets/autoload/autoload';
import 'stylesheets/new_registration';
import 'javascripts/application';

const importAll = (r) => r.keys().map(r);
importAll(require.context('../images', true, /\.(png|jpe?g|svg)$/));
